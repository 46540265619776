// Imports => Dependencies
import React from 'react';

// Imports => Constants
import { PERMISSIONS } from '@constants/permissions.constants';

// Imports => Tabs
import CompanyDetailTabs from '@tabs/company';
import ConfigurationDetailTabs from '@tabs/configuration';
import ContractDetailTabs from '@tabs/contract';
import ControlUnitDetailTabs from '@tabs/control-unit';
import ControlUnitTypeDetailTabs from '@tabs/control-unit-type';
import HammerDetailTabs from '@tabs/hammer';
import HammerTypeDetailTabs from '@tabs/hammer-type';
import PowerpackDetailTabs from '@tabs/powerpack';
import PowerpackTypeDetailTabs from '@tabs/powerpack-type';
import ProfileTabs from '@tabs/profile';
import ProjectDetailTabs from '@tabs/project';
import UserDetailTabs from '@tabs/user';
import AcUserOverviewClientsTab from '@tabs/user/overview-clients/overview-clients';
import AcUserOverviewIqipTab from '@tabs/user/overview-iqip/overview-iqip';
import AcUserOverviewMatrixTab from '@tabs/user/overview-matrix/overview-matrix';

export const COMPANY_DETAIL_TABS = [
  {
    id: 'overview',
    label: 'Overview',
    disabled: false,
    component: <CompanyDetailTabs.Overview />,
    allowed: [PERMISSIONS.COMPANY.READ],
  },
];

export const CONFIGURATION_DETAIL_TABS = [
  {
    id: 'overview',
    label: 'Overview',
    disabled: false,
    component: <ConfigurationDetailTabs.Overview />,
    allowed: [PERMISSIONS.CONFIGURATION.READ],
  },
];

export const CONTRACT_DETAIL_TABS = [
  {
    id: 'overview',
    label: 'Overview',
    disabled: false,
    component: <ContractDetailTabs.Overview />,
    allowed: [PERMISSIONS.CONTRACT.READ],
  },
];

export const CONTROL_UNIT_DETAIL_TABS = [
  {
    id: 'overview',
    label: 'Overview',
    disabled: false,
    component: <ControlUnitDetailTabs.Overview />,
    allowed: null,
  },
  {
    id: 'reports',
    label: 'Reports',
    disabled: false,
    component: <ControlUnitDetailTabs.Reports />,
    allowed: [PERMISSIONS.REPORT.READ],
  },
  {
    id: 'settings',
    label: 'Settings',
    disabled: false,
    component: <ControlUnitDetailTabs.Settings />,
    allowed: [PERMISSIONS.EQUIPMENT.READ_SETTINGS],
  },
];

export const CONTROL_UNIT_TYPE_DETAIL_TABS = [
  {
    id: 'overview',
    label: 'Overview',
    disabled: false,
    component: <ControlUnitTypeDetailTabs.Overview />,
    allowed: [PERMISSIONS.EQUIPMENT_TYPE.READ],
  },
  {
    id: 'software',
    label: 'Software',
    disabled: false,
    component: <ControlUnitTypeDetailTabs.Software />,
    allowed: [PERMISSIONS.EQUIPMENT_TYPE.READ],
  },
];

export const HAMMER_DETAIL_TABS = [
  {
    id: 'overview',
    label: 'Overview',
    disabled: false,
    component: <HammerDetailTabs.Overview />,
    allowed: null,
  },
  {
    id: 'parameters',
    label: 'Parameters',
    disabled: false,
    component: <HammerDetailTabs.Parameters />,
    allowed: [PERMISSIONS.EQUIPMENT.READ_PARAMETERS],
  },
];

export const HAMMER_TYPE_DETAIL_TABS = [
  {
    id: 'overview',
    label: 'Overview',
    disabled: false,
    component: <HammerTypeDetailTabs.Overview />,
    allowed: [PERMISSIONS.EQUIPMENT_TYPE.READ],
  },
  {
    id: 'parameters',
    label: 'Parameters',
    disabled: false,
    component: <HammerTypeDetailTabs.Parameters />,
    allowed: [PERMISSIONS.EQUIPMENT_TYPE.READ_PARAMETERS],
  },
];

export const POWERPACK_DETAIL_TABS = [
  {
    id: 'overview',
    label: 'Overview',
    disabled: false,
    component: <PowerpackDetailTabs.Overview />,
    allowed: null,
  },
  {
    id: 'parameters',
    label: 'Parameters',
    disabled: false,
    component: <PowerpackDetailTabs.Parameters />,
    allowed: [PERMISSIONS.EQUIPMENT.READ_PARAMETERS],
  },
];

export const POWERPACK_TYPE_DETAIL_TABS = [
  {
    id: 'overview',
    label: 'Overview',
    disabled: false,
    component: <PowerpackTypeDetailTabs.Overview />,
    allowed: [PERMISSIONS.EQUIPMENT_TYPE.READ],
  },

  {
    id: 'parameters',
    label: 'Parameters',
    disabled: false,
    component: <PowerpackTypeDetailTabs.Parameters />,
    allowed: [PERMISSIONS.EQUIPMENT_TYPE.READ_PARAMETERS],
  },
];

export const PROFILE_TABS = [
  {
    id: 'overview',
    label: 'Overview',
    disabled: false,
    component: <ProfileTabs.Overview />,
    allowed: null,
  },
];

export const PROJECT_DETAIL_TABS = [
  {
    id: 'overview',
    label: 'Overview',
    disabled: false,
    component: <ProjectDetailTabs.Overview />,
    allowed: [PERMISSIONS.PROJECT.READ],
  },
  {
    id: 'configurations',
    label: 'Configurations',
    disabled: false,
    component: <ProjectDetailTabs.Configurations />,
    allowed: [PERMISSIONS.CONFIGURATION.READ],
  },
  {
    id: 'pilelist',
    label: 'Pile List',
    disabled: false,
    component: <ProjectDetailTabs.PileList />,
    allowed: [PERMISSIONS.PILE.READ],
  },
  {
    id: 'reports',
    label: 'Reports',
    disabled: false,
    component: <ProjectDetailTabs.Reports />,
    allowed: [PERMISSIONS.REPORT.READ],
  },
];

export const USER_DETAIL_TABS = [
  {
    id: 'overview',
    label: 'Overview',
    disabled: false,
    component: <UserDetailTabs.Overview />,
    allowed: [PERMISSIONS.USER.READ],
  },
];

export const USER_OVERVIEW_TABS = [
  {
    id: 'clients',
    label: 'Clients',
    disabled: false,
    component: <AcUserOverviewClientsTab />,
    allowed: [PERMISSIONS.USER.READ],
  },
  {
    id: 'iqip',
    label: 'IQIP Users',
    disabled: false,
    component: <AcUserOverviewIqipTab />,
    allowed: [PERMISSIONS.USER.READ_ALL],
  },
  {
    id: 'matrix',
    label: 'Roles & Permissions',
    disabled: false,
    component: <AcUserOverviewMatrixTab />,
    allowed: [PERMISSIONS.PERMISSIONS.READ],
  },
];

export const TABS = {
  COMPANY_DETAIL: COMPANY_DETAIL_TABS,
  CONFIGURATION_DETAIL: CONFIGURATION_DETAIL_TABS,
  CONTRACT_DETAIL: CONTRACT_DETAIL_TABS,
  CONTROL_UNIT_DETAIL: CONTROL_UNIT_DETAIL_TABS,
  CONTROL_UNIT_TYPE_DETAIL: CONTROL_UNIT_TYPE_DETAIL_TABS,
  HAMMER_DETAIL: HAMMER_DETAIL_TABS,
  HAMMER_TYPE_DETAIL: HAMMER_TYPE_DETAIL_TABS,
  POWERPACK_DETAIL: POWERPACK_DETAIL_TABS,
  POWERPACK_TYPE_DETAIL: POWERPACK_TYPE_DETAIL_TABS,
  PROFILE: PROFILE_TABS,
  PROJECT_DETAIL: PROJECT_DETAIL_TABS,
  USER_DETAIL: USER_DETAIL_TABS,
  USER_OVERVIEW: USER_OVERVIEW_TABS,
};

export default TABS;
