export const ROLES = {
  ADMIN: 'admin',
  SALES_MANAGER: 'sales_manager',
  IQIP_PROJECT_MANAGER: 'iqip_project_manager',
  PROJECT_ENGINEER: 'project_engineer',
  GEOTECHNICAL_ADVISOR: 'geotechnical_advisor',
  IQIP_FLEET_MANAGER: 'global_fleet_manager',
  CUSTOMER_SERVICE: 'customer_service',
  SERVICE_ENGINEER: 'service_engineer',

  COMPANY_ADMIN: 'company_admin',
  COMPANY_FLEET_MANAGER: 'company_fleet_manager',
  COMPANY_PROJECT_MANAGER: 'company_project_manager',
  OPERATOR_A: 'operator_a',
  OPERATOR_B: 'operator_b',
  OPERATOR_C: 'operator_c',
};

export const IQIP_ROLES = [
  ROLES.ADMIN,
  ROLES.SALES_MANAGER,
  ROLES.IQIP_PROJECT_MANAGER,
  ROLES.PROJECT_ENGINEER,
  ROLES.GEOTECHNICAL_ADVISOR,
  ROLES.IQIP_FLEET_MANAGER,
  ROLES.CUSTOMER_SERVICE,
  ROLES.SERVICE_ENGINEER,
];

export const CLIENT_ROLES = [
  ROLES.COMPANY_ADMIN,
  ROLES.COMPANY_FLEET_MANAGER,
  ROLES.COMPANY_PROJECT_MANAGER,
  ROLES.OPERATOR_A,
  ROLES.OPERATOR_B,
  ROLES.OPERATOR_C,
];
