// Imports => Constants
import { ICONS } from '@constants/icons.constants';
import { TITLES } from '@constants/titles.constants';
import { PERMISSIONS } from '@constants/permissions.constants';
import { ROLES } from '@constants/roles.constants';

// Imports => Utilities
import { AcUUID } from '@utils/ac-uuid';

// Imports => Views
import {
  AcSSOLogin,
  AcLogin,
  AcForgotPassword,
  AcResetPassword,
  AcProfile,
  AcChangelog,
  AcDashboard,
  AcCompanyDetail,
  AcCompanyOverview,
  AcConfigurationDetail,
  AcConfigurationOverview,
  AcContractDetail,
  AcContractOverview,
  AcControlUnitDashboard,
  AcControlUnitDetail,
  AcControlUnitLiveview,
  AcControlUnitOverview,
  AcControlUnitTypeDetail,
  AcControlUnitTypeOverview,
  AcEquipmentDashboard,
  AcHammerDetail,
  AcHammerOverview,
  AcHammerTypeDetail,
  AcHammerTypeOverview,
  AcMyCompany,
  AcPowerpackDetail,
  AcPowerpackOverview,
  AcPowerpackTypeDetail,
  AcPowerpackTypeOverview,
  AcProjectDashboard,
  AcProjectDetail,
  AcProjectOverview,
  AcSettings,
  AcSupport,
  AcUserDetail,
  AcUserOverview,
  AcWelcome,
  AcStyleguide,
} from '@views';

export const PATHS = {
  SSO_LOGIN: '/auth/microsoft/callback',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  PROFILE: '/profile',
  DASHBOARD: '/dashboard',
  COMPANY_DETAIL: '/companies/:id',
  COMPANY_OVERVIEW: '/companies',
  CONFIGURATION_DETAIL: '/configurations/:id',
  CONFIGURATION_OVERVIEW: '/configurations',
  CONTRACT_DETAIL: '/contracts/:id',
  CONTRACT_OVERVIEW: '/contracts',
  CONTROL_UNIT_DASHBOARD: '/dashboard/control-units',
  CONTROL_UNIT_DETAIL: '/equipment/control-units/:id',
  CONTROL_UNIT_LIVEVIEW: '/equipment/control-units/liveview/:id',
  CONTROL_UNIT_OVERVIEW: '/equipment/control-units',
  CONTROL_UNIT_TYPE_DETAIL: '/types/control-units/:id',
  CONTROL_UNIT_TYPE_OVERVIEW: '/types/control-units',
  EQUIPMENT: '/equipment',
  EQUIPMENT_TYPES: '/types',
  EQUIPMENT_DASHBOARD: '/dashboard/equipment',
  HAMMER_DETAIL: '/equipment/hammers/:id',
  HAMMER_OVERVIEW: '/equipment/hammers',
  HAMMER_TYPE_DETAIL: '/types/hammers/:id',
  HAMMER_TYPE_OVERVIEW: '/types/hammers',
  MY_COMPANY: '/my-company',
  POWERPACK_DETAIL: '/equipment/powerpacks/:id',
  POWERPACK_OVERVIEW: '/equipment/powerpacks',
  POWERPACK_TYPE_DETAIL: '/types/powerpacks/:id',
  POWERPACK_TYPE_OVERVIEW: '/types/powerpacks',
  PROJECT_DETAIL: '/projects/:id',
  PROJECT_OVERVIEW: '/projects',
  PROJECT_DASHBOARD: '/dashboard/projects',
  SUPPORT: '/support',
  USER_DETAIL: '/users/:id',
  USER_OVERVIEW: '/users',
  WELCOME: '/welcome',
  CHANGELOG: '/whats-new',
  STYLEGUIDE: '/styleguide',
};

export const ROUTES = {
  SSO_LOGIN: {
    id: AcUUID(),
    name: 'SSO Login',
    label: null,
    pathname: PATHS.SSO_LOGIN,
    path: PATHS.SSO_LOGIN,
    component: AcSSOLogin,
    allowed: null,
    forbidden: false,
    exact: true,
  },
  LOGIN: {
    id: AcUUID(),
    name: 'Login',
    label: null,
    pathname: PATHS.LOGIN,
    path: PATHS.LOGIN,
    component: AcLogin,
    allowed: null,
    forbidden: false,
    exact: true,
  },
  FORGOT_PASSWORD: {
    id: AcUUID(),
    name: 'Forgot Password',
    label: null,
    pathname: PATHS.FORGOT_PASSWORD,
    path: PATHS.FORGOT_PASSWORD,
    component: AcForgotPassword,
    allowed: null,
    forbidden: false,
    exact: true,
  },
  RESET_PASSWORD: {
    id: AcUUID(),
    name: 'Reset Password',
    label: null,
    pathname: PATHS.RESET_PASSWORD,
    path: PATHS.RESET_PASSWORD,
    component: AcResetPassword,
    allowed: null,
    forbidden: false,
    exact: true,
  },
  DASHBOARD: {
    id: AcUUID(),
    name: 'Dashboard',
    label: TITLES.DASHBOARD,
    pathname: PATHS.DASHBOARD,
    path: PATHS.DASHBOARD,
    component: AcDashboard,
    icon: ICONS.DOTS_GRID,
    allowed: null,
    forbidden: true,
    exact: true,
  },
  MY_COMPANY: {
    id: AcUUID(),
    name: 'Company settings',
    label: TITLES.MY_COMPANY,
    pathname: PATHS.MY_COMPANY,
    path: PATHS.MY_COMPANY,
    component: AcMyCompany,
    icon: ICONS.APARTMENT,
    allowed: [PERMISSIONS.COMPANY.READ_SELF],
    forbidden: true,
    exact: true,
  },
  COMPANY_DETAIL: {
    id: AcUUID(),
    name: 'Company Detail',
    label: TITLES.COMPANY_DETAIL,
    pathname: PATHS.COMPANY_DETAIL,
    path: PATHS.COMPANY_DETAIL,
    component: AcCompanyDetail,
    allowed: [PERMISSIONS.COMPANY.READ],
    forbidden: true,
    exact: true,
  },
  COMPANY_OVERVIEW: {
    id: AcUUID(),
    name: 'Company Overview',
    label: TITLES.COMPANY_OVERVIEW,
    pathname: PATHS.COMPANY_OVERVIEW,
    path: PATHS.COMPANY_OVERVIEW,
    component: AcCompanyOverview,
    icon: ICONS.APARTMENT,
    allowed: [PERMISSIONS.COMPANY.READ_ALL],
    forbidden: true,
    exact: true,
  },
  CONFIGURATION_DETAIL: {
    id: AcUUID(),
    name: 'Configuration Detail',
    label: TITLES.CONFIGURATION_DETAIL,
    pathname: PATHS.CONFIGURATION_DETAIL,
    path: PATHS.CONFIGURATION_DETAIL,
    component: AcConfigurationDetail,
    allowed: [PERMISSIONS.CONFIGURATION.READ],
    forbidden: true,
    exact: true,
  },
  CONFIGURATION_OVERVIEW: {
    id: AcUUID(),
    name: 'Configuration Overview',
    label: TITLES.CONFIGURATION_OVERVIEW,
    pathname: PATHS.CONFIGURATION_OVERVIEW,
    path: PATHS.CONFIGURATION_OVERVIEW,
    component: AcConfigurationOverview,
    icon: ICONS.DEVICE_HUB,
    allowed: [PERMISSIONS.CONFIGURATION.READ],
    forbidden: true,
    exact: true,
  },
  CONTRACT_DETAIL: {
    id: AcUUID(),
    name: 'Contract Detail',
    label: TITLES.CONTRACT_DETAIL,
    pathname: PATHS.CONTRACT_DETAIL,
    path: PATHS.CONTRACT_DETAIL,
    component: AcContractDetail,
    allowed: [PERMISSIONS.CONTRACT.READ],
    forbidden: true,
    exact: true,
  },
  CONTRACT_OVERVIEW: {
    id: AcUUID(),
    name: 'Contract Overview',
    label: TITLES.CONTRACT_OVERVIEW,
    pathname: PATHS.CONTRACT_OVERVIEW,
    path: PATHS.CONTRACT_OVERVIEW,
    component: AcContractOverview,
    icon: ICONS.CLIPBOARD_TEXT_MULTIPLE_OUTLINE,
    allowed: [PERMISSIONS.CONTRACT.READ],
    forbidden: true,
    exact: true,
  },
  CONTROL_UNIT_DASHBOARD: {
    id: AcUUID(),
    name: 'Control Unit Dashboard',
    label: TITLES.CONTROL_UNIT_DASHBOARD,
    pathname: PATHS.CONTROL_UNIT_DASHBOARD,
    path: PATHS.CONTROL_UNIT_DASHBOARD,
    icon: ICONS.CONTROL_UNIT,
    component: AcControlUnitDashboard,
    allowed: [PERMISSIONS.EQUIPMENT.READ],
    forbidden: true,
    exact: true,
  },
  CONTROL_UNIT_DETAIL: {
    id: AcUUID(),
    name: 'Control Unit Detail',
    label: TITLES.CONTROL_UNIT_DETAIL,
    pathname: PATHS.CONTROL_UNIT_DETAIL,
    path: PATHS.CONTROL_UNIT_DETAIL,
    component: AcControlUnitDetail,
    allowed: [PERMISSIONS.EQUIPMENT.READ],
    forbidden: true,
    exact: true,
  },
  CONTROL_UNIT_LIVEVIEW: {
    id: AcUUID(),
    name: 'Control Unit Liveview',
    label: TITLES.CONTROL_UNIT_LIVEVIEW,
    pathname: PATHS.CONTROL_UNIT_DETAIL,
    path: PATHS.CONTROL_UNIT_LIVEVIEW,
    component: AcControlUnitLiveview,
    allowed: [PERMISSIONS.EQUIPMENT.READ],
    forbidden: true,
    exact: true,
  },
  CONTROL_UNIT_OVERVIEW: {
    id: AcUUID(),
    name: 'Control Unit Overview',
    label: TITLES.CONTROL_UNIT_OVERVIEW,
    pathname: PATHS.CONTROL_UNIT_OVERVIEW,
    path: PATHS.CONTROL_UNIT_OVERVIEW,
    component: AcControlUnitDashboard,
    icon: ICONS.CHEVRON_RIGHT,
    allowed: [PERMISSIONS.EQUIPMENT.READ],
    forbidden: true,
    exact: true,
  },
  CONTROL_UNIT_TYPE_DETAIL: {
    id: AcUUID(),
    name: 'Control Unit Type Detail',
    label: TITLES.CONTROL_UNIT_TYPE_DETAIL,
    pathname: PATHS.CONTROL_UNIT_TYPE_DETAIL,
    path: PATHS.CONTROL_UNIT_TYPE_DETAIL,
    component: AcControlUnitTypeDetail,
    allowed: [PERMISSIONS.EQUIPMENT_TYPE.READ],
    forbidden: true,
    exact: true,
  },
  CONTROL_UNIT_TYPE_OVERVIEW: {
    id: AcUUID(),
    name: 'Control Unit Type Overview',
    label: TITLES.CONTROL_UNIT_TYPE_OVERVIEW,
    pathname: PATHS.CONTROL_UNIT_TYPE_OVERVIEW,
    path: PATHS.CONTROL_UNIT_TYPE_OVERVIEW,
    component: AcControlUnitTypeOverview,
    icon: ICONS.CHEVRON_RIGHT,
    allowed: [PERMISSIONS.EQUIPMENT_TYPE.READ],
    forbidden: true,
    exact: true,
  },
  EQUIPMENT_DASHBOARD: {
    id: AcUUID(),
    name: 'Equipment Dashboard',
    label: TITLES.EQUIPMENT_DASHBOARD,
    pathname: PATHS.EQUIPMENT_DASHBOARD,
    path: PATHS.EQUIPMENT_DASHBOARD,
    component: AcEquipmentDashboard,
    icon: ICONS.EQUIPMENT,
    allowed: [PERMISSIONS.EQUIPMENT_TYPE.READ],
    forbidden: true,
    exact: true,
  },
  HAMMER_DETAIL: {
    id: AcUUID(),
    name: 'Hammer Detail',
    label: TITLES.HAMMER_DETAIL,
    pathname: PATHS.HAMMER_DETAIL,
    path: PATHS.HAMMER_DETAIL,
    component: AcHammerDetail,
    allowed: [PERMISSIONS.EQUIPMENT.READ],
    forbidden: true,
    exact: true,
  },
  HAMMER_OVERVIEW: {
    id: AcUUID(),
    name: 'Hammer Overview',
    label: TITLES.HAMMER_OVERVIEW,
    pathname: PATHS.HAMMER_OVERVIEW,
    path: PATHS.HAMMER_OVERVIEW,
    component: AcHammerOverview,
    icon: ICONS.CHEVRON_RIGHT,
    allowed: [PERMISSIONS.EQUIPMENT.READ],
    forbidden: true,
    exact: true,
  },
  HAMMER_TYPE_DETAIL: {
    id: AcUUID(),
    name: 'Hammer Type Detail',
    label: TITLES.HAMMER_TYPE_DETAIL,
    pathname: PATHS.HAMMER_TYPE_DETAIL,
    path: PATHS.HAMMER_TYPE_DETAIL,
    component: AcHammerTypeDetail,
    allowed: [PERMISSIONS.EQUIPMENT_TYPE.READ],
    forbidden: true,
    exact: true,
  },
  HAMMER_TYPE_OVERVIEW: {
    id: AcUUID(),
    name: 'Hammer Type Overview',
    label: TITLES.HAMMER_TYPE_OVERVIEW,
    pathname: PATHS.HAMMER_TYPE_OVERVIEW,
    path: PATHS.HAMMER_TYPE_OVERVIEW,
    component: AcHammerTypeOverview,
    icon: ICONS.CHEVRON_RIGHT,
    allowed: [PERMISSIONS.EQUIPMENT_TYPE.READ],
    forbidden: true,
    exact: true,
  },
  POWERPACK_DETAIL: {
    id: AcUUID(),
    name: 'Powerpack Detail',
    label: TITLES.POWERPACK_DETAIL,
    pathname: PATHS.POWERPACK_DETAIL,
    path: PATHS.POWERPACK_DETAIL,
    component: AcPowerpackDetail,
    allowed: [PERMISSIONS.EQUIPMENT.READ],
    forbidden: true,
    exact: true,
  },
  POWERPACK_OVERVIEW: {
    id: AcUUID(),
    name: 'Powerpack Overview',
    label: TITLES.POWERPACK_OVERVIEW,
    pathname: PATHS.POWERPACK_OVERVIEW,
    path: PATHS.POWERPACK_OVERVIEW,
    component: AcPowerpackOverview,
    icon: ICONS.CHEVRON_RIGHT,
    allowed: [PERMISSIONS.EQUIPMENT.READ],
    forbidden: true,
    exact: true,
  },
  POWERPACK_TYPE_DETAIL: {
    id: AcUUID(),
    name: 'Powerpack Type Detail',
    label: TITLES.POWERPACK_TYPE_DETAIL,
    pathname: PATHS.POWERPACK_TYPE_DETAIL,
    path: PATHS.POWERPACK_TYPE_DETAIL,
    component: AcPowerpackTypeDetail,
    allowed: [PERMISSIONS.EQUIPMENT_TYPE.READ],
    forbidden: true,
    exact: true,
  },
  POWERPACK_TYPE_OVERVIEW: {
    id: AcUUID(),
    name: 'Powerpack Type Overview',
    label: TITLES.POWERPACK_TYPE_OVERVIEW,
    pathname: PATHS.POWERPACK_TYPE_OVERVIEW,
    path: PATHS.POWERPACK_TYPE_OVERVIEW,
    component: AcPowerpackTypeOverview,
    icon: ICONS.CHEVRON_RIGHT,
    allowed: [PERMISSIONS.EQUIPMENT_TYPE.READ],
    forbidden: true,
    exact: true,
  },
  PROFILE: {
    id: AcUUID(),
    name: 'Profile',
    label: TITLES.PROFILE,
    pathname: PATHS.PROFILE,
    path: PATHS.PROFILE,
    component: AcProfile,
    icon: ICONS.ACCOUNT,
    allowed: null,
    forbidden: true,
    exact: true,
  },
  PROJECT_DASHBOARD: {
    id: AcUUID(),
    name: 'Project Dashboard',
    label: TITLES.DASHBOARD,
    pathname: PATHS.PROJECT_DASHBOARD,
    path: PATHS.PROJECT_DASHBOARD,
    component: AcProjectDashboard,
    icon: ICONS.APPS,
    allowed: [PERMISSIONS.PROJECT.READ],
    forbidden: true,
    exact: true,
  },
  PROJECT_DETAIL: {
    id: AcUUID(),
    name: 'Project Detail',
    label: TITLES.PROJECT_DETAIL,
    pathname: PATHS.PROJECT_DETAIL,
    path: PATHS.PROJECT_DETAIL,
    component: AcProjectDetail,
    icon: ICONS.FOLDER_MARKER_OUTLINE,
    allowed: [PERMISSIONS.PROJECT.READ],
    forbidden: true,
    exact: false,
  },
  PROJECT_OVERVIEW: {
    id: AcUUID(),
    name: 'Project Overview',
    label: TITLES.PROJECT_OVERVIEW,
    pathname: PATHS.PROJECT_OVERVIEW,
    path: PATHS.PROJECT_OVERVIEW,
    component: AcProjectOverview,
    icon: ICONS.FOLDER_MARKER_OUTLINE,
    allowed: [PERMISSIONS.PROJECT.READ],
    forbidden: true,
    exact: true,
  },
  SUPPORT: {
    id: AcUUID(),
    name: 'Support',
    label: TITLES.SUPPORT,
    pathname: PATHS.SUPPORT,
    path: PATHS.SUPPORT,
    component: AcSupport,
    icon: ICONS.HELP,
    allowed: null,
    forbidden: true,
    exact: true,
  },
  USER_DETAIL: {
    id: AcUUID(),
    name: 'User Detail',
    label: TITLES.USER_DETAIL,
    pathname: PATHS.USER_DETAIL,
    path: PATHS.USER_DETAIL,
    component: AcUserDetail,
    allowed: [PERMISSIONS.USER.READ],
    forbidden: true,
    exact: true,
  },
  USER_OVERVIEW: {
    id: AcUUID(),
    name: 'User Overview',
    label: TITLES.USER_OVERVIEW,
    pathname: PATHS.USER_OVERVIEW,
    path: PATHS.USER_OVERVIEW,
    component: AcUserOverview,
    icon: ICONS.GROUPS,
    allowed: [PERMISSIONS.USER.READ],
    forbidden: true,
    exact: true,
  },
  CHANGELOG: {
    id: AcUUID(),
    name: 'Changelog',
    label: TITLES.CHANGELOG,
    pathname: PATHS.CHANGELOG,
    path: PATHS.CHANGELOG,
    component: AcChangelog,
    icon: ICONS.LIGHTBULB_ON_OUTLINE,
    allowed: null,
    forbidden: true,
    exact: true,
  },
  WELCOME: {
    id: AcUUID(),
    name: 'Welcome',
    label: TITLES.WELCOME,
    pathname: PATHS.WELCOME,
    path: PATHS.WELCOME,
    component: AcWelcome,
    allowed: null,
    forbidden: true,
    exact: true,
  },
  STYLEGUIDE: {
    id: AcUUID(),
    name: 'Styleguide',
    label: TITLES.STYLEGUIDE,
    pathname: PATHS.STYLEGUIDE,
    path: PATHS.STYLEGUIDE,
    component: AcStyleguide,
    allowed: null,
    forbidden: true,
    exact: true,
  },
};

export const AUTHENTICATION_ROUTES = [
  ROUTES.SSO_LOGIN.path,
  ROUTES.LOGIN.path,
  ROUTES.FORGOT_PASSWORD.path,
  ROUTES.RESET_PASSWORD.path,
];

export const NAVIGATION_ROUTES = [
  ROUTES.PROJECT_DASHBOARD,
  // ROUTES.EQUIPMENT_DASHBOARD,
  // ROUTES.CONTROL_UNIT_DASHBOARD,
  ROUTES.PROJECT_OVERVIEW,
  ROUTES.CONTRACT_OVERVIEW,
  ROUTES.CONFIGURATION_OVERVIEW,
  {
    parent: {
      id: AcUUID(),
      label: TITLES.EQUIPMENT,
      pathname: PATHS.EQUIPMENT,
      path: PATHS.EQUIPMENT,
      icon: ICONS.EQUIPMENT,
      allowed: [PERMISSIONS.EQUIPMENT.READ],
    },
    children: [
      ROUTES.CONTROL_UNIT_OVERVIEW,
      ROUTES.HAMMER_OVERVIEW,
      ROUTES.POWERPACK_OVERVIEW,
    ],
  },
  {
    parent: {
      id: AcUUID(),
      label: TITLES.EQUIPMENT_TYPES,
      pathname: PATHS.EQUIPMENT_TYPES,
      path: PATHS.EQUIPMENT_TYPES,
      icon: ICONS.LIST_NUMBERED,
      allowed: [PERMISSIONS.EQUIPMENT_TYPE.READ],
    },
    children: [
      ROUTES.CONTROL_UNIT_TYPE_OVERVIEW,
      ROUTES.HAMMER_TYPE_OVERVIEW,
      ROUTES.POWERPACK_TYPE_OVERVIEW,
    ],
  },
  ROUTES.COMPANY_OVERVIEW,
  ROUTES.USER_OVERVIEW,
];

export const SUB_NAVIGATION_ROUTES = [
  ROUTES.PROFILE,
  ROUTES.MY_COMPANY,
  ROUTES.CHANGELOG,
];

export const DASHBOARD_ROUTES = {
  [ROLES.ADMIN]: ROUTES.PROJECT_DASHBOARD,
  [ROLES.SALES_MANAGER]: ROUTES.PROJECT_DASHBOARD,
  [ROLES.IQIP_PROJECT_MANAGER]: ROUTES.PROJECT_DASHBOARD,
  [ROLES.PROJECT_ENGINEER]: ROUTES.PROJECT_DASHBOARD,
  [ROLES.GEOTECHNICAL_ADVISOR]: ROUTES.PROJECT_DASHBOARD,
  [ROLES.IQIP_FLEET_MANAGER]: ROUTES.PROJECT_DASHBOARD,
  [ROLES.CUSTOMER_SERVICE]: ROUTES.PROJECT_DASHBOARD,
  [ROLES.SERVICE_ENGINEER]: ROUTES.PROJECT_DASHBOARD,

  [ROLES.COMPANY_ADMIN]: ROUTES.PROJECT_DASHBOARD,
  [ROLES.COMPANY_FLEET_MANAGER]: ROUTES.PROJECT_DASHBOARD,
  [ROLES.COMPANY_PROJECT_MANAGER]: ROUTES.PROJECT_DASHBOARD,
  [ROLES.OPERATOR_A]: ROUTES.PROJECT_DASHBOARD,
  [ROLES.OPERATOR_B]: ROUTES.PROJECT_DASHBOARD,
  [ROLES.OPERATOR_C]: ROUTES.PROJECT_DASHBOARD,
};

export const DEFAULT_ROUTE = ROUTES.PROJECT_DASHBOARD;
export const REDIRECT_ROUTE = ROUTES.LOGIN;
