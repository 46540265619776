export const PERMISSIONS = {
  ALERT: {
    READ: 'ALERT.READ',
    UPDATE: 'ALERT.UPDATE',
  },
  ADVANCED_PASSWORD: {
    READ: 'ADVANCED_PASSWORD.READ',
    UPDATE: 'ADVANCED_PASSWORD.UPDATE',
  },
  BASIC_PASSWORD: {
    READ: 'BASIC_PASSWORD.READ',
    UPDATE: 'BASIC_PASSWORD.UPDATE',
  },
  COMPANY: {
    READ: 'COMPANY.READ',
    READ_ALL: 'COMPANY.READ_ALL',
    READ_SELF: 'COMPANY.READ_SELF',

    UPDATE: 'COMPANY.UPDATE',
    UPDATE_ALL: 'COMPANY.UPDATE_ALL',

    CREATE: 'COMPANY.CREATE',
    IMPORT: 'COMPANY.IMPORT',
    DELETE: 'COMPANY.DELETE',
  },
  CONFIGURATION: {
    READ: 'CONFIGURATION.READ',
    READ_ALL: 'CONFIGURATION.READ_ALL',
    MANAGE: 'CONFIGURATION.MANAGE',
    IMPORT_IN_PROJECT: 'CONFIGURATION.IMPORT_IN_PROJECT',
    SEND_CONFIGURATION_READ: 'CONFIGURATION.SEND_CONFIGURATION_READ',
  },
  CONTRACT: {
    READ: 'CONTRACT.READ',
    READ_ALL: 'CONTRACT.READ_ALL',
    MANAGE: 'CONTRACT.MANAGE',
  },
  CREDENTIALS: {
    READ: 'CREDENTIALS.READ',
    MANAGE: 'CREDENTIALS.MANAGE',
  },
  EQUIPMENT: {
    CREATE: 'EQUIPMENT.CREATE',
    DELETE: 'EQUIPMENT.DELETE',
    MANAGE_DETAILS: 'EQUIPMENT.MANAGE_DETAILS',
    MANAGE_SETTINGS: 'EQUIPMENT.MANAGE_SETTINGS',
    MANAGE_PARAMETERS: 'EQUIPMENT.MANAGE_PARAMETERS',
    READ: 'EQUIPMENT.READ',
    READ_ALL: 'EQUIPMENT.READ_ALL',
    READ_PARAMETERS: 'EQUIPMENT.READ_PARAMETERS',
    READ_SETTINGS: 'EQUIPMENT.READ_SETTINGS',
  },
  EQUIPMENT_TYPE: {
    CREATE: 'EQUIPMENT_TYPE.CREATE',
    DELETE: 'EQUIPMENT_TYPE.DELETE',
    MANAGE_PARAMETERS: 'EQUIPMENT_TYPE.MANAGE_PARAMETERS',
    READ: 'EQUIPMENT_TYPE.READ',
    READ_PARAMETERS: 'EQUIPMENT_TYPE.READ_PARAMETERS',
    UPDATE: 'EQUIPMENT_TYPE.UPDATE',
  },
  JEAN_LUTZ_USERNAME: {
    READ: 'JEAN_LUTZ_USERNAME.READ',
    UPDATE: 'JEAN_LUTZ_USERNAME.UPDATE',
  },
  PILE: {
    CREATE: 'PILE.CREATE',
    DELETE: 'PILE.DELETE',
    EXPORT_CONTROL_UNIT: 'PILE.EXPORT_CONTROL_UNIT',
    EXPORT_XLSX: 'PILE.EXPORT_XLSX',
    IMPORT: 'PILE.IMPORT',
    READ: 'PILE.READ',
    READ_ALL: 'PILE.READ_ALL',
    READ_TRANSFERS: 'PILE.READ_TRANSFERS',
    READ_ALL_TRANSFERS: 'PILE.READ_ALL_TRANSFERS',
    SEND: 'PILE.SEND',
    UPDATE: 'PILE.UPDATE',
  },
  PROFILE: {
    READ_JEAN_LUTZ_CREDENTIALS: 'PROFILE.READ_JEAN_LUTZ_CREDENTIALS',
    MANAGE_JEAN_LUTZ_CREDENTIALS: 'PROFILE.MANAGE_JEAN_LUTZ_CREDENTIALS',
  },
  PROJECT: {
    CREATE: 'PROJECT.CREATE',
    CREATE_ALL: 'PROJECT.CREATE_ALL',
    DELETE: 'PROJECT.DELETE',
    IMPORT: 'PROJECT.IMPORT',
    READ: 'PROJECT.READ',
    READ_ALL: 'PROJECT.READ_ALL',
    READ_COMPANY_UID: 'PROJECT.READ_COMPANY_UID',
    READ_COMPANY_NAME: 'PROJECT.READ_COMPANY_NAME',
    UPDATE: 'PROJECT.UPDATE',
    UPDATE_COMPANY_UID: 'PROJECT.UPDATE_COMPANY_UID',
    UPDATE_COMPANY_NAME: 'PROJECT.UPDATE_COMPANY_NAME',
    UPDATE_CONFIGURATIONS: 'PROJECT.UPDATE_CONFIGURATIONS',
  },
  REPORT: {
    READ: 'REPORT.READ',
    DELETE: 'REPORT.DELETE',
    RESTORE: 'REPORT.RESTORE',
    DOWNLOAD: 'REPORT.DOWNLOAD',
    UPLOAD_FDL: 'REPORT.UPLOAD_FDL',
  },
  ROLES: {
    READ: 'ROLES.READ',
  },
  SOFTWARE: {
    READ: 'SOFTWARE.READ',
    READ_UNAVAILABLE: 'SOFTWARE.READ_UNAVAILABLE',
    CREATE: 'SOFTWARE.CREATE',
    UPDATE: 'SOFTWARE.UPDATE',
    DELETE: 'SOFTWARE.DELETE',
    PUSH: 'SOFTWARE.PUSH',
    DOWNLOAD: 'SOFTWARE.DOWNLOAD',
  },
  USER: {
    READ: 'USER.READ',
    READ_ALL: 'USER.READ_ALL',
    MANAGE: 'USER.MANAGE',
    MANAGE_ALL: 'USER.MANAGE_ALL',
    MANAGE_IQIP: 'USER.MANAGE_IQIP',
    IMPERSONATE: 'USER.IMPERSONATE',
    READ_PERMISSION: 'USER.READ_PERMISSIONS',
    READ_JL_NAME: 'JEAN_LUTZ_USERNAME.READ',
    UPDATE_JL_NAME: 'JEAN_LUTZ_USERNAME.UPDATE',
    READ_BASIC_PASS: 'BASIC_PASSWORD.READ',
    UPDATE_BASIC_PASS: 'BASIC_PASSWORD.UPDATE',
    READ_ADVANCED_PASS: 'ADVANCED_PASSWORD.READ',
    UPDATE_ADVANCED_PASS: 'ADVANCED_PASSWORD.UPDATE',
  },
  PERMISSIONS: {
    READ: 'PERMISSIONS.READ',
    MANAGE: 'PERMISSIONS.MANAGE',
  },
  NAVIGATION: {},
};

export const IQIP_ONLY_PERMISSIONS = [
  'COMPANY.CREATE',
  'COMPANY.DELETE',
  'COMPANY.IMPORT',
  'CREATE_ALL',
  'EQUIPMENT_TYPE',
  'EQUIPMENT_TYPES',
  'MANAGE_ALL',
  'MANAGE_IQIP',
  'PERMISSIONS.READ',
  'READ_ALL',
  'ROLES.READ',
  'SOFTWARE.CREATE',
  'SOFTWARE.DELETE',
  'SOFTWARE.READ_UNAVAILABLE',
  'SOFTWARE.UPDATE',
  'UPDATE_ALL',
  'USER.IMPERSONATE',
];

export const RESTRICTED_PERMISSIONS = ['PERMISSIONS.MANAGE'];

export const PERMISSION_DESCRIPTIONS = {
  'ADVANCED_PASSWORD.READ': 'Can read advanced password of user',
  'ADVANCED_PASSWORD.UPDATE': 'Can update advanced password of user',
  'ALERT.READ': 'Can read operational alerts',
  'ALERT.UPDATE': 'Can update operational alerts',
  'BASIC_PASSWORD.READ':
    'Can read basic password (control unit credentials) of user & default operator',
  'BASIC_PASSWORD.UPDATE':
    'Can update basic password (control unit credentials) of user & default operator',
  'COMPANY.CREATE': 'Can create a new company',
  'COMPANY.DELETE': 'Can delete a company',
  'COMPANY.IMPORT': 'Can import a new company from the ERP',
  'COMPANY.READ': 'Can read company overview & company details',
  'COMPANY.READ_ALL':
    'Can read company overview & company details <em>scope: IQIP / all companies</em>',
  'COMPANY.READ_SELF':
    'Can read own company details <em rel="client">scope: Client</em>',
  'COMPANY.UPDATE':
    'Can update company details <em rel="client">scope: Client</em>',
  'COMPANY.UPDATE_ALL':
    'Can update company details <em>scope: IQIP / all companies</em>',
  'CONFIGURATION.IMPORT_IN_PROJECT':
    'Can import or add configurations to projects',
  'CONFIGURATION.MANAGE': 'Can update configuration details',
  'CONFIGURATION.READ':
    'Can read configuration overview & configuration details <em rel="client">scope: Client</em>',
  'CONFIGURATION.READ_ALL':
    'Can read configuration overview & configuration details <em>scope: IQIP / all companies</em>',
  'CONFIGURATION.SEND_CONFIGURATION_READ':
    'Can read when the configuration has been last sent to control unit',
  'CONTRACT.MANAGE': 'Can update contract details',
  'CONTRACT.READ':
    'Can read contract overview & contract details <em rel="client">scope: Client</em>',
  'CONTRACT.READ_ALL':
    'Can read contract overview & contract details <em>scope: IQIP / all companies</em>',
  'CREDENTIALS.MANAGE': 'unknown',
  'CREDENTIALS.READ': 'unknown',
  'EQUIPMENT.CREATE': 'Can create new equipment',
  'EQUIPMENT.DELETE': 'Can delete equipment',
  'EQUIPMENT.MANAGE_DETAILS': 'Can update equipment details',
  'EQUIPMENT.MANAGE_PARAMETERS': 'Can update equipment parameters',
  'EQUIPMENT.MANAGE_SETTINGS': 'Can update equipment settings',
  'EQUIPMENT.READ':
    'Can read equipment overview & equipment details <em rel="client">scope: Client</em>',
  'EQUIPMENT.READ_ALL':
    'Can read equipment overview & equipment details  <em>scope: IQIP / all companies</em>',
  'EQUIPMENT.READ_PARAMETERS': 'Can read equipment parameters',
  'EQUIPMENT.READ_SETTINGS': 'Can read equipment settings',
  'EQUIPMENT_TYPE.CREATE': 'Can create new equipment-type',
  'EQUIPMENT_TYPE.DELETE': 'Can delete equipment-type',
  'EQUIPMENT_TYPE.MANAGE_PARAMETERS': 'Can update equipment-type parameters',
  'EQUIPMENT_TYPE.READ':
    'Can read equipment-type overview & equipment-type details ',
  'EQUIPMENT_TYPE.READ_PARAMETERS': 'Can read equipment-type parameters',
  'EQUIPMENT_TYPE.UPDATE': 'Can update equipment-type details',
  'EQUIPMENT_TYPE.CREATE_SOFTWARE': 'Deprecated, moved to SOFTWARE permissions',
  'EQUIPMENT_TYPE.DELETE_SOFTWARE': 'Deprecated, moved to SOFTWARE permissions',
  'EQUIPMENT_TYPE.DOWNLOAD_SOFTWARE':
    'Deprecated, moved to SOFTWARE permissions',
  'EQUIPMENT_TYPE.MANAGE_SOFTWARE': 'Deprecated, moved to SOFTWARE permissions',
  'EQUIPMENT_TYPE.UPDATE_SOFTWARE': 'Deprecated, moved to SOFTWARE permissions',
  'EQUIPMENT_TYPE.READ_SOFTWARE': 'Deprecated, moved to SOFTWARE permissions',
  'EQUIPMENT_TYPE.READ_UNAVAILABLE_SOFTWARE':
    'Deprecated, moved to SOFTWARE permissions',
  'JEAN_LUTZ_USERNAME.READ': 'Can read control unit username of user',
  'JEAN_LUTZ_USERNAME.UPDATE': 'Can update control unit username of user',
  'PERMISSIONS.MANAGE': 'Can update roles & permissions using the matrix',
  'PERMISSIONS.READ': 'Can read roles & permissions matrix',
  'PILE.CREATE': 'Can create new pile list',
  'PILE.DELETE': 'Can delete pile list',
  'PILE.EXPORT_CONTROL_UNIT':
    'Can export pile list file suitable for control unit',
  'PILE.EXPORT_XLSX': 'Can export pile list file as XLSX',
  'PILE.IMPORT': 'Can import new or replace exisiting pile list',
  'PILE.READ':
    'Can read pile lists in project <em rel="client">scope: Client</em>',
  'PILE.READ_ALL':
    'Can read pile lists in project <em>scope: IQIP / all companies</em>',
  'PILE.READ_ALL_TRANSFERS':
    'Can read pile list transfers <em>scope: IQIP / all companies</em>',
  'PILE.READ_TRANSFERS':
    'Can read pile list transfers <em rel="client">scope: Client</em>',
  'PILE.SEND': 'Can send pile list to control unit',
  'PILE.UPDATE': 'Can update pile list details',
  'PROFILE.MANAGE_JEAN_LUTZ_CREDENTIALS':
    'Can update own control unit username and password',
  'PROFILE.READ_JEAN_LUTZ_CREDENTIALS':
    'Can read own control unit username and password',
  'PROJECT.CREATE':
    'Can create new project <em rel="client">scope: Client</em>',
  'PROJECT.CREATE_ALL':
    'Can create new project for all companies <em>scope: IQIP / all companies</em>',
  'PROJECT.DELETE': 'Can delete project',
  'PROJECT.IMPORT': 'unknown',
  'PROJECT.READ':
    'Can read project overview & project details <em rel="client">scope: Client</em>',
  'PROJECT.READ_ALL':
    'Can read project overview & project details <em>scope: IQIP / all companies</em>',
  'PROJECT.READ_COMPANY_NAME': "Can read field 'Customer Name' in project",
  'PROJECT.READ_COMPANY_UID':
    "Can read field 'Customer Project UID' in project",
  'PROJECT.UPDATE': 'Can update project details',
  'PROJECT.UPDATE_COMPANY_NAME':
    "Can update value of field 'Customer Name' in project",
  'PROJECT.UPDATE_COMPANY_UID':
    "Can update value of field 'Customer Project UID' in project",
  'PROJECT.UPDATE_CONFIGURATIONS': 'Lorem ipsum',
  'REPORT.DELETE': 'Can delete reports',
  'REPORT.DOWNLOAD': 'Can download reports',
  'REPORT.READ': 'Can read reports',
  'REPORT.RESTORE': 'Can restore deleted reports',
  'REPORT.UPLOAD_FDL': 'Can upload FDL file',
  'ROLES.READ': 'Can read user roles',
  'SOFTWARE.CREATE': 'Can create new software version',
  'SOFTWARE.DELETE': 'Can delete software version',
  'SOFTWARE.DOWNLOAD': 'Can download software version update file',
  'SOFTWARE.PUSH': 'Can push software version update to control unit',
  'SOFTWARE.READ': 'Can read software versions',
  'SOFTWARE.READ_UNAVAILABLE': 'Can read unavailable software versions',
  'SOFTWARE.UPDATE': 'Can update software version details',
  'USER.IMPERSONATE': 'Can impersonate other user',
  'USER.MANAGE': 'Can update user details <em rel="client">scope: Client</em>',
  'USER.MANAGE_ALL':
    'Can update user details <em>scope: IQIP / all companies</em>',
  'USER.MANAGE_IQIP': 'Can update user details <em>scope: IQIP users</em>',
  'USER.READ':
    'Can read user overview & user details <em rel="client">scope: Client</em>',
  'USER.READ_ALL':
    'Can read user overview & user details <em>scope: IQIP / all companies</em>',
};

export default PERMISSIONS;
