export const TITLES = {
  BASE: 'MIQIP Portal',
  ACCOUNT: 'Account',
  ADD_COMPANY: 'Add Company',
  ADD_CONFIGURATION: 'Add Configuration',
  ADD_CONTRACT: 'Add Contract',
  ADD_CONTROL_UNIT: 'Add Control unit',
  ADD_CONTROL_UNIT_TYPE: 'Add Control unit type',
  ADD_HAMMER: 'Add Hammer',
  ADD_HAMMER_TYPE: 'Add Hammer type',
  ADD_POWERPACK: 'Add Powerpack',
  ADD_POWERPACK_TYPE: 'Add Powerpack type',
  ADD_PROJECT: 'Add Project',
  ADD_PILE_LIST: 'Add Pile List',
  IMPORT_PILE_LIST: 'Import Pile List',
  ADD_USER: 'Add User',
  BASIC_OPERATOR: 'Default Operator',
  EDIT_DEFAULT_OPERATOR_CREDENTIALS: 'Edit Default Operator credentials',
  ASSIGN_EQUIPMENT: 'Add equipment to configuration',
  ASSIGN_OPERATORS: 'Add operators to configuration',
  ASSIGN_CONFIGURATIONS: 'Add configurations to project',
  ASSIGN_EQUIPMENT_FROM_CONFIGURATIONS: 'Add equipment to contract',
  ASSIGN_COMPANIES_TO_USER: 'Assign companies to user',
  ASSIGN_ROLES_TO_USER: 'Assign roles to user',
  CHANGE_PROJECT_LOCATION: 'Change project location',
  CHANGELOG: `What's new?`,
  CANNOT_MODIFY_CONFIGURATION: 'Cannot modify configuration',
  CANNOT_DELETE_CONFIGURATION: 'Cannot delete configuration',
  COMPANY: 'Company',
  COMPANY_OVERVIEW: 'Companies',
  CONFIGURATION_OVERVIEW: 'Configurations',
  CONFIGURATION: 'Configuration',
  CONFIGURATIONS: 'Configurations',
  SAVE_BEFORE_SEND: 'Save changes before sending',
  SAVE_BEFORE_LEAVE: 'Leave page without saving',
  LIST_ALREADY_EXISTS: 'Matching pile list found',
  CONTRACT_OVERVIEW: 'Contracts',
  CONTRACT: 'Contract',
  CONTRACTS: 'Contracts',
  CONTROL_UNIT: 'Control unit',
  CONTROL_UNITS: 'Control units',
  CONTROL_UNIT_DASHBOARD: 'Control units',
  CONTROL_UNIT_LIVEVIEW: 'Control unit liveview',
  CONTROL_UNIT_OVERVIEW: 'Control units',
  CONTROL_UNIT_TYPE: 'Control unit type',
  CONTROL_UNIT_TYPE_OVERVIEW: 'Control units',
  CREDENTIALS_UPDATED: 'Credentials changed',
  DASHBOARD: 'Dashboard',
  DATE_RANGE_SELECTION: 'Select a date range',
  DELETE_CONFIRM: 'Delete',
  DELETE_COMPANY: 'Delete Company',
  DELETE_CONFIGURATION: 'Delete Configuration',
  DELETE_CONTRACT: 'Delete Contract',
  DELETE_CONTROL_UNIT: 'Delete Control unit',
  DELETE_CONTROL_UNIT_TYPE: 'Delete Control unit type',
  DELETE_HAMMER: 'Delete Hammer',
  DELETE_HAMMER_TYPE: 'Delete Hammer type',
  DELETE_PILELIST: 'Delete Pile list',
  DELETE_SELECTED_PILES: 'Delete piles',
  DELETE_POWERPACK: 'Delete Powerpack',
  DELETE_POWERPACK_TYPE: 'Delete Powerpack type',
  DELETE_PROJECT: 'Delete Project',
  DELETE_REPORT: 'Delete Report',
  DELETED_REPORTS: 'Deleted Reports',
  DELETE_USER: 'Delete User',
  DELETE_SOFTWARE_VERSION: 'Delete software version',
  DETAILS: 'Details',
  DISMISS_MESSAGE: 'Dismiss message',
  DOWNLOAD_SOFTWARE_UPDATE: 'Download software update',
  DOWNLOAD_TEMPLATE: 'Download template',
  DOWNLOAD_CONTROL_UNIT_FILE: 'Download control unit file',
  EDIT_COMPANY: 'Edit Company',
  EDIT_CONFIGURATION: 'Edit Configuration',
  EDIT_CONTRACT: 'Edit Contract',
  EDIT_CONTROL_UNIT: 'Edit Control unit',
  EDIT_CONTROL_UNIT_TYPE: 'Edit Control unit type',
  EDIT_HAMMER: 'Edit Hammer',
  EDIT_HAMMER_TYPE: 'Edit Hammer type',
  EDIT_LOCATION: 'Edit Location',
  EDIT_POWERPACK: 'Edit Powerpack',
  EDIT_POWERPACK_TYPE: 'Edit Powerpack type',
  EDIT_PROFILE: 'Edit Profile',
  EDIT_PROFILE_CREDENTIALS: 'Edit Control unit credentials',
  EDIT_PROJECT: 'Edit Project',
  EDIT_SOFTWARE_UPDATE: 'Edit Software Update',
  EDIT_USER: 'Edit User',
  EDIT_USER_CREDENTIALS: 'Edit Control unit credentials',
  EQUIPMENT: 'Equipment',
  EQUIPMENT_DASHBOARD: 'Equipment Dashboard',
  EQUIPMENT_TYPES: 'Equipment types',
  EQUIPMENT_DASHBOARD: 'Equipment',
  EXPIRATION_DATE_ACTIVE: 'Active expiration date',
  EXPIRATION_DATE_REMOVE: 'Remove expiration date',
  EXPIRATION_DATE_SET: 'Set expiration date',
  FAILED_TO_DELETE_RESOURCE: 'Failed to delete this resource',
  FAILED_TO_DELETE_PILELIST: 'Failed to delete this pile list',
  FAILED_TO_DELETE_SELECTED_PILES: 'Failed to delete selected piles',
  FAILED_TO_DELETE_COMPANY: 'Failed to delete this company',
  FAILED_TO_DELETE_CONFIGURATION: 'Failed to delete this configuration',
  FAILED_TO_DELETE_CONTRACT: 'Failed to delete this contract',
  FAILED_TO_DELETE_CONTROL_UNIT: 'Failed to delete this control unit',
  FAILED_TO_DELETE_CONTROL_UNIT_TYPE: 'Failed to delete this control unit type',
  FAILED_TO_DELETE_HAMMER: 'Failed to delete this hammer',
  FAILED_TO_DELETE_HAMMER_TYPE: 'Failed to delete this hammer type',
  FAILED_TO_DELETE_POWERPACK: 'Failed to delete this powerpack',
  FAILED_TO_DELETE_POWERPACK_TYPE: 'Failed to delete this powerpack type',
  FAILED_TO_DELETE_PROJECT: 'Failed to delete this project',
  FAILED_TO_DELETE_REPORT: 'Failed to delete report',
  FAILED_TO_DELETE_USER: 'Failed to delete this user',
  FAILED_TO_DELETE_SOFTWARE_VERSION: 'Failed to delete software version',
  FEEDBACK: 'Feedback',
  FORGOT_PASSWORD: 'Password recovery',
  GUIDES: 'Guides & Downloads',
  HAMMER: 'Hammer',
  ACCOUNT_SWITCH: 'Company Switcher',
  HAMMERS: 'Hammers',
  HAMMER_OVERVIEW: 'Hammers',
  HAMMER_TYPE: 'Hammer type',
  HAMMER_TYPE_OVERVIEW: 'Hammers',
  KEYBOARD_SHORTCUTS: 'Keyboard shortcuts',
  LAST_SENT_CONFIGURATION: 'Last sent configuration',
  LIVE_VIEW: 'Live view',
  LOGIN: 'Login',
  MESSAGE_CENTER: 'Message center',
  MY_COMPANY: 'Company settings',
  MY_PROJECTS: 'My projects',
  MODIFY_CONFIGURATION: 'Modify configuration',
  MULTI_SELECT: 'Make a selection',
  NEW_COMPANY: 'New Company',
  NEW_CONFIGURATION: 'New configuration',
  NEW_CONTRACT: 'New Contract',
  NEW_CONTROL_UNIT: 'New Control unit',
  NEW_CONTROL_UNIT_TYPE: 'New Control unit type',
  NEW_EQUIPMENT: 'New Equipment',
  NEW_HAMMER: 'New Hammer',
  NEW_HAMMER_TYPE: 'New Hammer type',
  NEW_POWERPACK: 'New Powerpack',
  NEW_POWERPACK_TYPE: 'New Powerpack type',
  NEW_PROJECT: 'New Project',
  NEW_SOFTWARE_UPDATE: 'New Software Update',
  NEW_USER: 'Add New User',
  NO_CONTROL_UNIT_AVAILABLE: 'No control unit available',
  NO_OPERATOR_IN_CONFIGURATION: 'No operator in configuration',
  NO_INTERNET_CONNECTION: 'You appear to be offline',
  ORDER_LIST: 'Original order list',
  OPERATIONAL_ALERTS: 'alerts',
  OPERATIONAL_ALERTS_DETAIL: 'alert',
  OPERATIONAL_CENTER: 'Operational Center',
  FAILED_TO_ATTACH_USER_TO_COMPANY: 'Failed to add user to company',
  USER_EXISTS: 'User already exists',
  OVERVIEW: 'Overview',
  PARAMETERS: 'Parameters',
  PARAMS: {
    CAP: 'Cap',
    DEB: 'Deb',
    FLOW: 'Flow',
    OPTIONS: 'Options',
    OTHER: 'Other',
    PRESSURE_RANGE: 'Pressure range',
    TIMING: 'Timing',
    VALVE: 'Valve',
    POCLAIN: 'Poclain',
  },
  POWERPACK: 'Powerpack',
  POWERPACK_OVERVIEW: 'Powerpacks',
  POWERPACK_TYPE: 'Powerpack type',
  POWERPACK_TYPE_OVERVIEW: 'Powerpacks',
  PROFILE: 'Your profile & settings',
  PROJECT: 'Project',
  REMOVE_PERMISSION: 'Unassign permission',
  ASSIGN_PERMISSION: 'Assign permission',
  PROJECT_DETAIL: 'Project',
  PROJECTS: 'Projects',
  PROJECT_DASHBOARD: 'Project Dashboard',
  PROJECT_OVERVIEW: 'Projects',
  RECENT_UPDATES: 'Recent updates',
  RESOLVE_ALERT: 'Resolve alert',
  REMOVE_EQUIPMENT: 'Remove equipment',
  REMOVE_CONFIGURATION_FROM_PROJECT: 'Remove configuration from project',
  REPORTS: 'Reports',
  RESET_PASSWORD: 'New password',
  RESOURCE_LINKED_TO: 'Failed to delete this resource',
  ROLE_RESTRICTED_TO_IHC_IQIP_RENTAL: 'User role is restricted',
  SAVE_CONFIGURATION: 'Save configuration',
  SELECT_SOFTWARE_UPDATE: 'Select software update',
  SELECT_COMPANY: 'Select company',
  SELECT_CONTROL_UNIT: 'Select a control unit',
  SELECT_POWER_PACKS: 'Select power pack(s)',
  SELECT_HAMMERS: 'Select hammer(s)',
  SEND_CONFIGURATION_TO_CONTROL_UNIT: 'Send configuration to control unit',
  SEND_TO_CONTROL_UNIT: 'Send to control unit',
  SESSION_TIMEOUT: 'Your session is about to expire',
  SESSION_EXPIRED: 'Your session has expired',
  SETTINGS: 'Settings',
  SUPPORT: 'Help & Support',
  SUPPORT_CENTER: 'Help & Support',
  TERMS_AND_CONDITIONS: 'Terms & Conditions',
  TRANSFER_CONTROL_UNIT: 'Transfer Control unit',
  TRANSFER_HAMMER: 'Transfer Hammer',
  TRANSFER_POWERPACK: 'Transfer Powerpack',
  UNASSIGN_OPERATOR: 'Unassign operator',
  UPLOAD_AND_SEND_TO_CONTROL_UNIT: 'Upload pile list and send to control unit',
  USER_OVERVIEW: 'Users',
  USER: 'User',
  USERS: 'Users',
  WELCOME: 'Welcome',
  YOUR_PROFILE_AND_SETTINGS: 'Your profile and settings',
  ACTIVE_COMPANY: 'Active company',
  YOUR_ACTIVE_COMPANY: 'Your active company',
  SELECT_ACTIVE_COMPANY: 'Select your active company',
};
