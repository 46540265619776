const API = '/api';
const ADD_CONFIGURATIONS = '/add-configurations';
const ADD_EQUIPMENT = '/add-equipment';
const ADD_PILING_LIST = '/add-piling-list';
const ADD_TO_COMPANY = '/attach-to-company';
const ADD_USER = '/add-user';
const ALERTS = '/alerts';
const ASSIGNABLE_ROLES = '/assignable-roles';
const ASSIGN_PERMISSION = '/attach-permission';
const AUTH = '/auth';
const COMPANIES = '/companies';
const COMPANY = '/company';
const CONFIGURATIONS = '/configurations';
const CONTRACTS = '/contracts';
const CONTROL_UNIT = '/control-unit';
const CONTROL_UNITS = '/control-units';
const DASHBOARD = '/dashboard';
const DETAILS = '/details';
const DEVICES = '/devices';
const DISMISS = '/dismiss';
const DOWNLOAD = '/download';
const DOWNLOAD_CONFIGURATION = '/download-config';
const EQUIPMENT = '/equipment';
const EQUIPMENT_TYPES = '/equipment-types';
const ERP = '/erp';
const EXISTS = '/exists';
const EXPORT = '/export';
const FORGOT_PASSWORD = '/forgot-password';
const HAMMERS = '/hammers';
const IMPERSONATE = '/impersonate';
const IMPORT_COMPANY = '/import';
const JEAN_LUTZ = '/jean-lutz';
const LIVE = '/live';
const LOGIN = '/token';
const LOGOUT = '/logout';
const MEDIA = '/media';
const MY_COMPANY = '/my-company';
const MY_COMPANY_LOGO = `${MY_COMPANY}/logo`;
const NOTIFICATIONS = '/notifications';
const OAUTH = '/oauth';
const PERMISSIONS = '/permissions';
const PILES = '/piles';
const PILE_LISTS = '/pile-lists';
const POWERPACKS = '/powerpacks';
const PROFILE = '/my-profile';
const PROJECTS = '/projects';
const PUSH = '/push';
const REMOVE_CONFIGURATIONS = '/remove-configurations';
const REMOVE_EQUIPMENT = '/remove-equipment';
const REMOVE_PERMISSION = '/detach-permission';
const REMOVE_USER = '/remove-user';
const REPORTS = '/reports';
const RESET_PASSWORD = '/reset-password';
const RESTORE = '/restore';
const ROLES = '/roles';
const SELECTION = '/selection';
const SEND = '/send';
const SEND_CONFIGURATION = '/send-config';
const SOFTWARE = '/software';
const SSO = '/microsoft';
const STOP_IMPERSONATE = '/impersonate';
const SYNC_COMPANY = '/import';
const TRANSFERS = '/transfers';
const XLS = '/xls';
const XLSX = '/xlsx';

const TABLE = '/table';
const UI = '/ui';
const USERS = '/users';

// V1
export const ENDPOINTS = {
  ALERTS: {
    OPEN: {
      GET: `${API}${ALERTS}/active`,
      GET_BY_ID: (id) => `${API}/alerts/${id}`, //NEW
      RESOLVE_ALERT: (id) => `${API}${ALERTS}/${id}/resolve`, //PUT
    },
    RESOLVED: {
      GET: `${API}${ALERTS}/resolved`,
      GET: `${API}/alerts/active`,
      GET_BY_ID: (id) => `${API}/alerts/active/${id}`,
    },
    RESOLVED: {
      GET: `${API}/alerts/resolved`,
      GET_BY_ID: (id) => `${API}/alerts/resolved/${id}`,
    },
  },
  OAUTH: {
    LOGIN: `${OAUTH}${LOGIN}`, // POST
    FORGOT_PASSWORD: `${API}${FORGOT_PASSWORD}`, // POST
    RESET_PASSWORD: `${API}${RESET_PASSWORD}`, // POST
    LOGOUT: `${API}${LOGOUT}`, // POST
    IMPERSONATE: (_id) => `${API}${USERS}${IMPERSONATE}/${_id}`, // GET
    STOP_IMPERSONATE: (_id) => `${API}${USERS}${STOP_IMPERSONATE}/${_id}`, // GET
    REFRESH: `${OAUTH}${LOGIN}`, // POST
    SSO_URL: `${AUTH}${SSO}`, // REDIRECT
  },
  COMPANIES: {
    LIST: `${API}${COMPANIES}`, // GET
    GET_BY_ID: (_id) => `${API}${COMPANIES}/${_id}`, // GET
    STORE: `${API}${COMPANIES}`, // POST
    UPDATE: (_id) => `${API}${COMPANIES}/${_id}`, // PUT
    DELETE: (_id) => `${API}${COMPANIES}/${_id}`, // DELETE
  },
  CONFIGURATIONS: {
    LIST: `${API}${CONFIGURATIONS}`, // GET
    GET_BY_ID: (_id) => `${API}${CONFIGURATIONS}/${_id}`, // GET
    STORE: `${API}${CONFIGURATIONS}`, // POST
    UPDATE: (_id) => `${API}${CONFIGURATIONS}/${_id}`, // PUT
    DELETE: (_id) => `${API}${CONFIGURATIONS}/${_id}`, // DELETE
    SEND_TO_CONTROL_UNIT: (_id, _project) =>
      `${API}${CONFIGURATIONS}/${_id}${SEND_CONFIGURATION}/${_project}`,
    DOWNLOAD: (_id) =>
      `${API}${CONFIGURATIONS}/${_id}${DOWNLOAD_CONFIGURATION}`,
    ADD_USER: (_id) => `${API}${CONFIGURATIONS}/${_id}${ADD_USER}`,
    REMOVE_USER: (_id) => `${API}${CONFIGURATIONS}/${_id}${REMOVE_USER}`,
    ADD_EQUIPMENT: (_id) => `${API}${CONFIGURATIONS}/${_id}${ADD_EQUIPMENT}`,
    REMOVE_EQUIPMENT: (_id) =>
      `${API}${CONFIGURATIONS}/${_id}${REMOVE_EQUIPMENT}`,
  },
  CONTRACTS: {
    LIST: `${API}${CONTRACTS}`, // GET
    CONFIGURATIONS: (_id) => `${API}${CONTRACTS}/${_id}${CONFIGURATIONS}`, // GET
    GET_BY_ID: (_id) => `${API}${CONTRACTS}/${_id}`, // GET
    STORE: `${API}${CONTRACTS}`, // POST
    UPDATE: (_id) => `${API}${CONTRACTS}/${_id}`, // PUT
    DELETE: (_id) => `${API}${CONTRACTS}/${_id}`, // DELETE
    ADD_EQUIPMENT: (_id) => `${API}${CONTRACTS}/${_id}${CONFIGURATIONS}`, // POST
    REMOVE_EQUIPMENT: (_id) => `${API}${CONTRACTS}/${_id}${EQUIPMENT}`, // DELETE
    NOTIFICATIONS: `${API}${CONTRACTS}${NOTIFICATIONS}`, // GET
    DISMISS: (_id) => `${API}${CONTRACTS}${NOTIFICATIONS}/${_id}${DISMISS}`, // DELETE,
    EQUIPMENTS: {
      EXP_DATE: (id) => `${API}${CONTRACTS}/${id}${EQUIPMENT}/expiration-date`, // POST,`
    },
    DETAILS: (id) => `${API}${CONTRACTS}/${id}${DETAILS}`, // GET,`
  },
  EQUIPMENT: {
    LIST: `${API}${EQUIPMENT}`, // GET
    CONTROL_UNITS: {
      LIST: `${API}${EQUIPMENT}${TABLE}${CONTROL_UNITS}`, // GET
      REPORTS: (_id) => `${API}${EQUIPMENT}/${_id}${REPORTS}`, // GET
      GET_BY_ID: (_id) => `${API}${EQUIPMENT}/${_id}`, // GET
      LIVE_VIEW: (_id) => `${API}${EQUIPMENT}/${_id}${LIVE}`, // GET
      STORE: `${API}${EQUIPMENT}`, // POST
      UPDATE: (_id) => `${API}${EQUIPMENT}/${_id}`, // PUT
      DELETE: (_id) => `${API}${EQUIPMENT}/${_id}`, // DELETE
      UPDATE_SOFTWARE: (_id) => `${API}${SOFTWARE}/${_id}${PUSH}`, // POST
      DOWNLOAD_SOFTWARE: (_id) => `${API}${SOFTWARE}/${_id}${DOWNLOAD}`, // GET
    },
    HAMMERS: {
      LIST: `${API}${EQUIPMENT}${TABLE}${HAMMERS}`, // GET
      GET_BY_ID: (_id) => `${API}${EQUIPMENT}/${_id}`, // GET
      STORE: `${API}${EQUIPMENT}`, // POST
      UPDATE: (_id) => `${API}${EQUIPMENT}/${_id}`, // PUT
      DELETE: (_id) => `${API}${EQUIPMENT}/${_id}`, // DELETE
    },
    POWERPACKS: {
      LIST: `${API}${EQUIPMENT}${TABLE}${POWERPACKS}`, // GET
      GET_BY_ID: (_id) => `${API}${EQUIPMENT}/${_id}`, // GET
      STORE: `${API}${EQUIPMENT}`, // POST
      UPDATE: (_id) => `${API}${EQUIPMENT}/${_id}`, // PUT
      DELETE: (_id) => `${API}${EQUIPMENT}/${_id}`, // DELETE
    },
  },
  EQUIPMENT_TYPES: {
    CONTROL_UNITS: {
      LIST: `${API}${EQUIPMENT_TYPES}${CONTROL_UNITS}`, // GET
      TABLE: `${API}${EQUIPMENT_TYPES}${TABLE}${CONTROL_UNITS}`, // GET
      GET_BY_ID: (_id) => `${API}${EQUIPMENT_TYPES}${CONTROL_UNITS}/${_id}`, // GET
      STORE: `${API}${EQUIPMENT_TYPES}${CONTROL_UNITS}`, // POST
      UPDATE: (_id) => `${API}${EQUIPMENT_TYPES}${CONTROL_UNITS}/${_id}`, // PUT
      DELETE: (_id) => `${API}${EQUIPMENT_TYPES}/${_id}`, // DELETE
      MEDIA: (_id) => `${API}${EQUIPMENT_TYPES}/${_id}${MEDIA}`, // DELETE
      SOFTWARE: `${API}${SOFTWARE}`, // GET
      UPDATE_SOFTWARE: (_id) => `${API}${SOFTWARE}/${_id}`, // POST
      DOWNLOAD_SOFTWARE: (_id) => `${API}${SOFTWARE}/${_id}${DOWNLOAD}`, // GET
      DELETE_SOFTWARE: (_id) => `${API}${SOFTWARE}/${_id}`, // DELETE
    },
    HAMMERS: {
      LIST: `${API}${EQUIPMENT_TYPES}${HAMMERS}`, // GET
      TABLE: `${API}${EQUIPMENT_TYPES}${TABLE}${HAMMERS}`, // GET
      GET_BY_ID: (_id) => `${API}${EQUIPMENT_TYPES}${HAMMERS}/${_id}`, // GET
      STORE: `${API}${EQUIPMENT_TYPES}${HAMMERS}`, // POST
      UPDATE: (_id) => `${API}${EQUIPMENT_TYPES}${HAMMERS}/${_id}`, // PUT
      DELETE: (_id) => `${API}${EQUIPMENT_TYPES}/${_id}`, // DELETE
      MEDIA: (_id) => `${API}${EQUIPMENT_TYPES}/${_id}${MEDIA}`, // DELETE
    },
    POWERPACKS: {
      LIST: `${API}${EQUIPMENT_TYPES}${POWERPACKS}`, // GET
      TABLE: `${API}${EQUIPMENT_TYPES}${TABLE}${POWERPACKS}`, // GET
      GET_BY_ID: (_id) => `${API}${EQUIPMENT_TYPES}${POWERPACKS}/${_id}`, // GET
      STORE: `${API}${EQUIPMENT_TYPES}${POWERPACKS}`, // POST
      UPDATE: (_id) => `${API}${EQUIPMENT_TYPES}${POWERPACKS}/${_id}`, // PUT
      DELETE: (_id) => `${API}${EQUIPMENT_TYPES}/${_id}`, // DELETE
      MEDIA: (_id) => `${API}${EQUIPMENT_TYPES}/${_id}${MEDIA}`, // DELETE
    },
  },
  ERP: {
    LIST_COMPANIES: `${API}${ERP}${COMPANIES}`,
    LIST_CONTRACTS: `${API}${ERP}${CONTRACTS}`,
    GET_CONTRACT: (id) => `${API}${ERP}${CONTRACTS}/${id}`,
    IMPORT: `${API}${ERP}${IMPORT_COMPANY}`,
    SYNC_COMPANY: (id) => `${API}${ERP}${COMPANIES}/${id}`,
  },
  JEAN_LUTZ: {
    CONTROL_UNITS: `${API}${JEAN_LUTZ}${CONTROL_UNITS}`, // GET
    HAMMERS: `${API}${JEAN_LUTZ}${HAMMERS}`, // GET
    POWERPACKS: `${API}${JEAN_LUTZ}${POWERPACKS}`, // GET
  },
  MY_COMPANY: {
    GET: `${API}${MY_COMPANY}`, // GET
    UPDATE: `${API}${MY_COMPANY}`, // PUT
    LOGO: {
      ADD: `${API}${MY_COMPANY_LOGO}`, // POST`
      REMOVE: `${API}${MY_COMPANY_LOGO}`, // DEL`
    },
  },
  PROFILE: {
    WHOAMI: `${API}${PROFILE}`, // GET
    UPDATE: `${API}${PROFILE}`, // POST
  },
  PILE_LISTS: {
    DOWNLOAD_XML: (_id) => `${API}${PILE_LISTS}/${_id}/xml`,
    LIST: (_id) => `${API}${PILE_LISTS}/${_id}`,
  },
  PROJECTS: {
    LIST: `${API}${PROJECTS}`, // GET
    DASHBOARD: `${API}${DASHBOARD}${PROJECTS}`, // GET
    GET_BY_ID: (_id) => `${API}${PROJECTS}/${_id}`, // GET
    STORE: `${API}${PROJECTS}`, // POST
    UPDATE: (_id) => `${API}${PROJECTS}/${_id}`, // PUT
    DELETE: (_id) => `${API}${PROJECTS}/${_id}`, // DELETE
    ADD_CONFIGURATIONS: (_id) =>
      `${API}${PROJECTS}/${_id}${ADD_CONFIGURATIONS}`, // POST
    REMOVE_CONFIGURATIONS: (_id) =>
      `${API}${PROJECTS}/${_id}${REMOVE_CONFIGURATIONS}`, // DELETE
    REMOVE_OPERATOR: (_id) => `${API}${PROJECTS}/${_id}${REMOVE_USER}`,
    REMOVE_REPORT: (_project_id, _pile_id) =>
      `${API}${PROJECTS}/${_project_id}${REPORTS}/${_pile_id}`,
    RESTORE_REPORTS: (_project_id) =>
      `${API}${PROJECTS}/${_project_id}${REPORTS}${RESTORE}`, // PUT
    REPORTS: (_id) => `${API}${PROJECTS}/${_id}${REPORTS}`, // GET
    DOWNLOAD_REPORT: (_project_id, _id) =>
      `${API}${PROJECTS}/${_project_id}${REPORTS}/${_id}`, // GET
    DOWNLOAD_REPORTS: (_project_id) =>
      `${API}${PROJECTS}/${_project_id}${REPORTS}${DOWNLOAD}`,
    UPLOAD_PILE_LIST: (_id) => `${API}${PROJECTS}/${_id}${ADD_PILING_LIST}`, // POST
    GET_XLSX_URL: (_project_id) =>
      `${API}${PROJECTS}/${_project_id}/piling-list${DOWNLOAD}`,
    PILELIST: {
      INDEX: (_project_id) => `${API}${PROJECTS}/${_project_id}${PILE_LISTS}`, // GET
      STORE: (_project_id) => `${API}${PROJECTS}/${_project_id}${PILE_LISTS}`, // POST
      ALL_PILES: (_project_id) => `${API}${PROJECTS}/${_project_id}${PILES}`, // POST
      SHOW: (_project_id, _pilelist_id) =>
        `${API}${PROJECTS}/${_project_id}${PILE_LISTS}/${_pilelist_id}`, // GET
      UPDATE: (_project_id, _pilelist_id) =>
        `${API}${PROJECTS}/${_project_id}${PILE_LISTS}/${_pilelist_id}`, // PUT
      DELETE: (_project_id, _pilelist_id) =>
        `${API}${PROJECTS}/${_project_id}${PILE_LISTS}/${_pilelist_id}`, // DELETE
      PILES: {
        VALIDATE: (_project_id, _pilelist_id, _pile_id) =>
          `${API}${PROJECTS}/${_project_id}${PILE_LISTS}/${_pilelist_id}/${_pile_id}`, // PATCH
        PATCH: (_project_id, _pilelist_id) =>
          `${API}${PROJECTS}/${_project_id}${PILE_LISTS}/${_pilelist_id}`, // PATCH
        DELETE: (_project_id, _pilelist_id) =>
          `${API}${PROJECTS}/${_project_id}${PILE_LISTS}/${_pilelist_id}${SELECTION}`, // DELETE
      },
      DOWNLOAD: {
        XLS: (_project_id, _pilelist_id) =>
          `${API}${PROJECTS}/${_project_id}${PILE_LISTS}/${_pilelist_id}${EXPORT}${XLS}`, // GET
        CONTROL_UNIT: (_project_id, _pilelist_id) =>
          `${API}${PROJECTS}/${_project_id}${PILE_LISTS}/${_pilelist_id}${EXPORT}${CONTROL_UNIT}`, // GET
      },
      SENDABLE_DEVICES: (_project_id, _pilelist_id) =>
        `${API}${PROJECTS}/${_project_id}${PILE_LISTS}/${_pilelist_id}${DEVICES}`, // GET
      SEND_TO_DEVICE: (_project_id, _pilelist_id) =>
        `${API}${PROJECTS}/${_project_id}${PILE_LISTS}/${_pilelist_id}${SEND}`, // POST,
    },
    TRANSFERS: {
      INDEX: (_project_id) =>
        `${API}${PROJECTS}/${_project_id}${PILE_LISTS}${TRANSFERS}`, // GET
      DOWNLOAD: {
        XLS: (_project_id, _transfer_id) =>
          `${API}${PROJECTS}/${_project_id}${PILE_LISTS}${TRANSFERS}/${_transfer_id}${DOWNLOAD}${XLS}`, // GET
        CONTROL_UNIT: (_project_id, _transfer_id) =>
          `${API}${PROJECTS}/${_project_id}${PILE_LISTS}${TRANSFERS}/${_transfer_id}${DOWNLOAD}${CONTROL_UNIT}`, // GET
      },
    },
  },
  REPORTS: {
    DOWNLOAD: (_id) => `${API}${REPORTS}/${_id}`, // GET
  },
  USERS: {
    LIST: `${API}${USERS}`, // GET
    GET_BY_ID: (_id) => `${API}${USERS}/${_id}`, // GET
    STORE: `${API}${USERS}`, // POST
    UPDATE: (_id) => `${API}${USERS}/patch/${_id}`, // PUT
    DELETE: (_id) => `${API}${USERS}/${_id}`, // DELETE
    EXISTS: `${API}${USERS}${EXISTS}`, // POST
    ADD_TO_COMPANY: (_id) => `${API}${USERS}/${_id}${ADD_TO_COMPANY}`, // PUT
    ASSIGNABLE_ROLES: `${API}${USERS}${ASSIGNABLE_ROLES}`, // GET
    IMPERSONATE: (_id) => `${API}${USERS}${IMPERSONATE}/${_id}`, // GET
    STOP_IMPERSONATE: (_id) => `${API}${USERS}${IMPERSONATE}/${_id}`, // DELETE
  },
  PERMISSIONS: {
    LIST: `${API}${PERMISSIONS}`, // GET
    ROLES: `${API}${ROLES}`, // GET
    ROLE_AND_PERMISSIONS: (_role) => `${API}${ROLES}/${_role}`, // GET
    ASSIGN_PERMISSION: `${API}${ROLES}${ASSIGN_PERMISSION}`, // PATCH
    REMOVE_PERMISSION: `${API}${ROLES}${REMOVE_PERMISSION}`, // PATCH
  },
};

export default ENDPOINTS;
